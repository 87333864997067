import { Box, Paper, Skeleton, Typography } from "@mui/material";
import InfoLabel from "components/InfoLabel";
import { Doughnut } from "react-chartjs-2";
import { useQuery } from "react-query";
import { authorizedFetch } from "utils";
import { BOLT_SERVICE_URL } from "utils/constants";
import { useInView } from "react-intersection-observer";

const colorsArray = [
  "#F4845D",
  "#6F7BF7",
  "#CC52E2",
  "#6EBDE7",
  "#31D18E",
  "#B199F2",
  "#F3AF3D",
  "#7EDECC",
  "#DA6561",
  "#EA92D6",
];

const FlaggedChargers = ({
  // chargerCountLoading: isLoading,
  // ChargerCountData: data,
  disableTotal,
}: any) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const { isLoading, data } = useQuery(
    ["GET flagged chargers"],
    () =>
      authorizedFetch(`${BOLT_SERVICE_URL}/v2/charger/flagged-chargers-count`),
    { enabled: inView },
  );

  const { isLoading: areIssueCategoriesLoading, data: issueCategoriesData } =
    useQuery(
      ["getIssueCategories"],
      () => authorizedFetch(`${BOLT_SERVICE_URL}/v2/charger/issue-categories`),
      { enabled: inView },
    );

  const chartData = data?.data || {};

  delete chartData.underMaintenanceCount; // Remove underMaintenanceCount from the chart (overlapping data)

  const issueCategories = [
    ...(issueCategoriesData?.data || []),
    // { key: "underMaintenanceCount", value: "Under Maintenance" },
  ];

  const totalChargers = Object.values(chartData).reduce(
    (acc: any, value: any) => acc + value,
    0,
  ) as number;

  function format(n: number) {
    return n.toLocaleString();
  }

  return (
    <Paper
      sx={{
        p: { xs: 3, md: 3.5 },
      }}
      ref={ref}
    >
      <Box display="flex" flexDirection="column" height={1}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography variant="h3" mr={{ xs: 1, md: 2.5 }}>
            Flagged Chargers
          </Typography>
          {!disableTotal ? (
            isLoading ? (
              <Skeleton
                variant="rectangular"
                width={95}
                height={28}
                sx={{ borderRadius: 10 }}
              />
            ) : (
              <InfoLabel dense label="Total" value={format(totalChargers)} />
            )
          ) : null}
        </Box>
        {isLoading || areIssueCategoriesLoading ? (
          <Skeleton
            variant="rectangular"
            sx={{ mt: 2, flexGrow: 1, borderRadius: 1 }}
          />
        ) : (
          <Box
            sx={{
              flexGrow: 1,
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              alignItems: "center",
              gap: 2,
            }}
          >
            {/* Legend */}
            <Box display="grid" gap={{ xs: 2, md: 4 }} ml={{ xs: 0, md: 4 }}>
              {Object.keys(chartData)
                .slice(0, 5) // Show only the top 5 categories
                .map((key, i) => ({
                  label: issueCategories.find(
                    (category: any) => category.key === key,
                  )?.value,
                  value: chartData[key],
                }))
                .map((el, i) => (
                  <Box key={i} display="flex" alignItems="center">
                    <Box
                      alignSelf="stretch"
                      width="5px"
                      borderRadius={50}
                      bgcolor={colorsArray[i]}
                    />
                    <Box px={1.75} py={0.25}>
                      <Box fontSize={12}>{el.label}</Box>
                      <Box
                        color={(theme) => theme.customColors.text.plain}
                        fontSize={{ xs: 16, md: 20 }}
                        lineHeight="1.1em"
                        fontWeight={700}
                      >
                        {el.value}
                      </Box>
                    </Box>
                  </Box>
                ))}
            </Box>
            {/* Chart */}
            <Box position="relative" width={1} height={1}>
              <Doughnut
                style={{
                  position: "absolute",
                  maxHeight: "100%",
                  maxWidth: 300,
                  top: "50%",
                  left: "50%",
                  transform: "translateX(-50%) translateY(-50%)",
                  zIndex: 2,
                }}
                data={(canvas) => {
                  return {
                    datasets: [
                      {
                        data: Object.values(chartData),
                        backgroundColor: colorsArray,
                        hoverBackgroundColor: colorsArray,
                        borderColor: colorsArray,
                        hoverBorderColor: colorsArray,
                        borderWidth: 0,
                        hoverBorderWidth: 10,
                        cutout: "60%",
                        hoverOffset: 0,
                        borderJoinStyle: "miter",
                        // spacing: 1,
                      },
                    ],
                    labels: Object.keys(chartData).map(
                      (key) =>
                        issueCategories.find(
                          (category: any) => category.key === key,
                        )?.value,
                    ),
                  };
                }}
                options={{
                  layout: {
                    padding: 16,
                  },
                  plugins: {
                    legend: {
                      display: false,
                    },
                    tooltip: {
                      displayColors: false,
                      callbacks: {
                        title: (context) => {
                          const total = context[0].dataset.data.reduce(
                            (a, b) => a + b,
                            0,
                          );
                          const percentage =
                            ((context[0].raw as number) / total) * 100;
                          return `${percentage.toLocaleString(undefined, { maximumFractionDigits: 2 })}%`;
                        },
                        label: (context) => {
                          return [
                            `${context.formattedValue} charger${(context.raw as number) > 1 ? "s" : ""}`,
                            `Issue: ${context.label}`,
                          ];
                        },
                      },
                      cornerRadius: 0,
                      titleFont: {
                        size: 20,
                      },
                      bodyFont: {
                        size: 12,
                      },
                      padding: 12,
                    },
                  },
                }}
              />
              <Box
                sx={{
                  zIndex: 1,
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translateX(-50%) translateY(-50%)",
                  pointerEvents: "none",
                  textAlign: "center",
                  color: (theme) => theme.customColors.text.plain,
                }}
              >
                <Typography
                  fontSize={{ xs: 18, md: 32 }}
                  fontWeight={700}
                  lineHeight="1em"
                >
                  {format(totalChargers)}
                </Typography>
                <Typography fontSize={14}>
                  Flagged
                  <br />
                  Chargers
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default FlaggedChargers;
