import { Circle, HighlightOff } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { queryClient } from "index";
import { useEffect, useState } from "react";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { useQuery } from "react-query";
import { authorizedFetch, setLoader, snackbar } from "utils";
import { BOLT_SERVICE_URL } from "utils/constants";

interface VendorProps {
  open: boolean;
  handleClose: () => void;
  data: any;
  isFlagged: boolean;
}

const FlagChargerDialog: React.FC<VendorProps> = ({
  open,
  handleClose,
  data,
  isFlagged,
}) => {
  const [phone, setPhone] = useState<any>("");
  const [answered, setAnswered] = useState<any>("");
  const [issue, setIssue] = useState<any>("");
  const [underMaintenance, setUnderMaintenance] = useState(false);
  const [note, setNote] = useState("");

  const { isLoading: areIssueCategoriesLoading, data: issueCategoriesData } =
    useQuery(
      ["getIssueCategories"],
      () => authorizedFetch(`${BOLT_SERVICE_URL}/v2/charger/issue-categories`),
      { enabled: !!open },
    );

  useEffect(() => {
    if (data && open) {
      setPhone("");
      setAnswered("");
      setIssue("");
      setUnderMaintenance(false);
      setNote("");
    }
  }, [data, open]);

  function onConfirm() {
    const latestFlagMetaData = data?.charger?.latestFlagMetaData;
    if (
      isFlagged &&
      (!latestFlagMetaData ||
        !latestFlagMetaData?.contactedThrough ||
        !latestFlagMetaData?.note)
    ) {
      snackbar.error("Error fetching latest flag metadata");
      return;
    }

    setLoader(true);
    authorizedFetch(
      `${BOLT_SERVICE_URL}/v2/charger/${data.charger.chargerId}/flag`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: isFlagged
          ? {
              // contactedThrough: latestFlagMetaData.contactedThrough,
              // answered: latestFlagMetaData.answered,
              issueCategories: [],
              underMaintenance: false,
              note,
            }
          : {
              contactedThrough: phone,
              answered: answered === "Yes" ? true : false,
              issueCategories: [issue],
              underMaintenance,
              note,
            },
      },
    )
      .then((res) => {
        setLoader(false);
        if (res.meta.success) {
          snackbar.success(
            `Charger ${isFlagged ? "un" : ""}flagged successfully`,
          );
          queryClient.resetQueries("getChargers");
          queryClient.resetQueries("getCharger");
          handleClose();
        } else {
          snackbar.error(`Error ${isFlagged ? "un" : ""}flagging charger`);
        }
      })
      .catch((err) => {
        setLoader(false);
        snackbar.error(`Error ${isFlagged ? "un" : ""}flagging charger`);
      });
  }

  let phoneList = [];

  if (data?.incharge?.phoneNumber) {
    phoneList.push(
      `Incharge: ${formatPhoneNumberIntl(data?.incharge?.phoneNumber) || data?.incharge?.phoneNumber}`,
    );
  }
  if (data?.owner?.phone) {
    phoneList.push(
      `Owner: ${formatPhoneNumberIntl(data?.owner?.phone) || data?.owner?.phone}`,
    );
  }
  if (data?.owner?.altPhone1) {
    phoneList.push(
      `Alternate No. 1: ${formatPhoneNumberIntl(data?.owner?.altPhone1) || data?.owner?.altPhone1}`,
    );
  }
  if (data?.owner?.altPhone2) {
    phoneList.push(
      `Alternate No. 2: ${formatPhoneNumberIntl(data?.owner?.altPhone2) || data?.owner?.altPhone2}`,
    );
  }

  const disabled = !isFlagged
    ? [answered, phone, issue, note].includes("")
    : !note;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: 400,
          width: 1,
          "& .MuiInputBase-root": {
            fontSize: 14,
            borderRadius: 1,
            p: "3.5px 5px",
          },
        },
      }}
    >
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          {isFlagged ? "Unflag" : "Flag"} Charger
          <IconButton
            children={<HighlightOff />}
            color="inherit"
            onClick={handleClose}
            sx={{ transform: "translate(8px, -8px)" }}
          />
        </Box>
      </DialogTitle>
      <DialogContent>
        {/* <Typography
          sx={{
            py: 2,
            display: "flex",
            pt: 2,
            fontSize: 14,
            fontWeight: "light",
          }}
        >
          No. of Contact Attempts:
          <strong style={{ marginLeft: 15, fontWeight: "bold" }}>2</strong>
        </Typography> */}
        <Typography
          sx={{
            mb: 1,
            display: "flex",
            alignItems: "center",
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          {data.charger.chargerId}:
          <Tooltip
            placement="right"
            title={
              data.charger.health === "HEALTHY"
                ? "Used Within Last 15 Days"
                : data.charger.health === "MODERATE"
                  ? "Used Between Last 16-30 Days"
                  : data.charger.health === "CRITICAL"
                    ? "Used Between Last 31-45 Days"
                    : data.charger.health === "INACTIVE"
                      ? "Used Before 45 Days"
                      : "Not available"
            }
          >
            <IconButton
              size="small"
              sx={{
                ml: 1,
                color:
                  data.charger.health === "HEALTHY"
                    ? "#01A229"
                    : data.charger.health === "MODERATE"
                      ? "#62D16A"
                      : data.charger.health === "CRITICAL"
                        ? "#FFCC27"
                        : data.charger.health === "INACTIVE"
                          ? "#EB5C5C"
                          : "Not available",
              }}
              children={<Circle fontSize="small" />}
            />
          </Tooltip>
        </Typography>

        {/* <Box sx={{ display: "flex", pt: 2 }}>
          <Typography sx={{ py: 2, display: "flex" }}>
            Add Contact Attempt:
          </Typography>
          <ButtonGroup
            size="small"
            aria-label="small outlined button group"
            sx={{ height: 35, mt: 1, ml: 2 }}
          >
            {displayCounter && <Button onClick={handleDecrement}>-</Button>}
            {displayCounter && <Button disabled>{state}</Button>}
            <Button onClick={handleIncrement}>+</Button>
          </ButtonGroup>
        </Box> */}

        {!isFlagged && (
          <>
            <Typography className="label" sx={{ mt: 2 }}>
              Contacted Through
            </Typography>
            <Select
              size="small"
              fullWidth
              className="primary"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              displayEmpty
            >
              <MenuItem disabled value="">
                <em>Select</em>
              </MenuItem>
              {phoneList?.map((filter, i) => (
                <MenuItem key={i} value={filter}>
                  {filter}
                </MenuItem>
              ))}
            </Select>
            <Typography className="label" sx={{ mt: 2 }}>
              Answered
            </Typography>
            <Select
              size="small"
              fullWidth
              className="primary"
              value={answered}
              onChange={(e) => setAnswered(e.target.value)}
              displayEmpty
            >
              <MenuItem disabled value="">
                <em>Select</em>
              </MenuItem>
              {["Yes", "No"].map((el, i) => (
                <MenuItem key={i} value={el}>
                  {el}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
        <Typography className="label" sx={{ mt: 2 }}>
          Issue
        </Typography>
        {!isFlagged ? (
          <Select
            size="small"
            fullWidth
            className="primary"
            value={issue}
            onChange={(e) => {
              setIssue(e.target.value);
              // On autofill, we get a string
              // const newValues =
              //   typeof e.target.value === "string"
              //     ? e.target.value.split(",")
              //     : e.target.value;
              // setIssues(newValues);
            }}
            displayEmpty
            // multiple
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em>Select</em>;
              }
              return issueCategoriesData?.data?.find(
                (el: any) => el.key === selected,
              )?.value;
              // return issueCategoriesData?.data
              //   ?.filter((el: any) => selected.includes(el.key))
              //   .map((el: any) => el.value)
              //   .join(", ");
            }}
          >
            <MenuItem disabled value="">
              <em>{areIssueCategoriesLoading ? "Loading..." : "Select"}</em>
            </MenuItem>
            {(issueCategoriesData?.data?.constructor === Array
              ? issueCategoriesData.data
              : []
            ).map((el: any, i: number) => (
              <MenuItem key={i} value={el.key}>
                {/* <Checkbox
                  checked={issue.includes(el.key)}
                  size="small"
                  sx={{ my: -0.5 }}
                /> */}
                {el.value}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Box component="ul" sx={{ listStyle: "disc", pl: 2, fontSize: 14 }}>
            {areIssueCategoriesLoading ? (
              <li>Loading...</li>
            ) : (
              (issueCategoriesData?.data?.constructor === Array
                ? issueCategoriesData.data
                : []
              )
                .filter((el: any) =>
                  data?.charger?.flags?.some((el2: any) => el.key === el2),
                )
                .map((issue: any, i: number) => <li key={i}>{issue.value}</li>)
            )}
          </Box>
        )}
        {!isFlagged && (
          <FormControlLabel
            sx={{
              mt: 2,
              "& .MuiFormControlLabel-label": {
                fontSize: 14,
              },
            }}
            control={
              <Checkbox
                size="small"
                checked={underMaintenance}
                onChange={(e) => setUnderMaintenance(e.target.checked)}
              />
            }
            label="Under Maintenance"
          />
        )}
        <Typography className="label" sx={{ mt: !isFlagged ? 2 : 2.5 }}>
          {isFlagged ? "Resolution Comments" : "Comments"}
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={4}
          placeholder="Type comments here"
          value={note}
          onChange={(e: any) => setNote(e.target.value)}
          sx={{
            "& .MuiInputBase-root": {
              px: "16px",
              py: "12px",
            },
          }}
        />
        {isFlagged && data?.charger?.attributes?.underMaintenance === true && (
          <Alert severity="info" sx={{ mt: 2 }}>
            <Typography variant="body2">
              This charger is under maintenance. Unflagging will mark it as no
              longer under maintenance.
            </Typography>
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onConfirm} disabled={disabled}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FlagChargerDialog;
