import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { HighlightOff } from "@mui/icons-material";
import { Avatar, Box, IconButton, Skeleton, Tab, Tabs } from "@mui/material";
import {
  authorizedFetch,
  drawer,
  getDarkModePreference,
  GlobalState,
  titleCase,
  // validateLatLng,
} from "utils";
import moment from "moment";
import { Wrapper } from "@googlemaps/react-wrapper";
import { GMAPS_API_KEY, LEASE_URL, RETAIL_URL } from "utils/constants";
import { useQuery } from "react-query";

// import { type } from "os";

import Table from "components/Table";

const InfoDrawer = (data: any) => {
  const open = useSelector((state: GlobalState) => state.global.drawer.open);

  const table = [
    { header: "Basic Details" },
    { label: "Name", value: data?.data?.name },
    {
      label: "Type",
      value: data?.data?.type,
    },
    {
      label: "Created At",
      value: moment(data?.data?.createdAt).format("MMM DD YYYY, hh:mm a"),
    },
  ];

  const [tab, setTab] = useState(0);

  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );

  const url = `${RETAIL_URL}/fence/${data?.data?._id}`;
  const { isLoading, data: fenceData } = useQuery(
    ["getSingleFence", data],
    () => authorizedFetch(url)
  );

  const vehiclesUrl = `${LEASE_URL}/company/vehicles?all=true`;

  const { data: vehiclesData } = useQuery("getVehicles", () =>
    authorizedFetch(vehiclesUrl)
  );

  const [vehicles, setVehicles] = useState<any>();

  useEffect(() => {
    if (vehiclesData) {
      let vehicleArray = vehiclesData?.data?.vehicles?.filter((el: any) =>
        data?.data?.vins?.some((elId: any) => el?.vin === elId)
      );

      setVehicles(vehicleArray);
    }
  }, [vehiclesData, data]);

  useEffect(() => {
    if (open) {
      setTab(0);
    }
  }, [open]);

  console.log(data?.data?.vin);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            px: 3,
            py: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: isDarkMode ? "#000" : "#3D3D3D",
            fontWeight: 500,
            color: "#fff",
          }}
        >
          {data?.data?.name ? data?.data?.name : "Geofence"}
          <Box display="grid" gridTemplateColumns="repeat(2, auto)" gap={1}>
            <IconButton
              children={<HighlightOff />}
              color="inherit"
              size="small"
              onClick={() => drawer.close()}
            />
          </Box>
        </Box>
        <Box px={2} pt={2}>
          <Tabs
            className="less-dense"
            value={tab}
            onChange={(e, tab) => setTab(tab)}
          >
            <Tab label="Info" />
            <Tab label="Map View" />
            <Tab label="Vehicles" />
          </Tabs>
        </Box>
        {tab === 0 && (
          <Box flexGrow={1} overflow="auto">
            <Box
              sx={{
                px: 3,
                pt: 2.5,
                "& .table": {
                  borderCollapse: "collapse",
                  width: 1,
                  fontSize: 14,
                  lineHeight: "16px",
                  "& td": {
                    py: 2,
                    px: 2,
                  },
                  "& .bold": {
                    fontWeight: 500,
                  },
                  "& .header": {
                    px: 2,
                    py: 1,
                    position: "relative",
                    "& td ": {
                      position: "absolute",
                      verticalAlign: "middle",
                      backgroundColor: (theme) => theme.customColors.header,
                      width: 1,
                      borderRadius: "4px",
                      fontSize: 16,
                      fontWeight: 600,
                      "& span": {
                        display: "inline-block",
                        transform: "translateY(1px)",
                      },
                    },
                    "& .textVehicle ": {
                      position: "absolute",
                      verticalAlign: "middle",
                      backgroundColor: (theme) => theme.customColors.header,
                      width: 1,
                      borderRadius: "4px",
                      fontSize: 16,
                      fontWeight: 600,
                      "& span": {
                        display: "inline-block",
                        transform: "translateY(1px)",
                      },
                    },
                  },
                  "& .first > td": {
                    pt: 9,
                  },
                  "& .last > td": {
                    pb: 3,
                  },
                },
              }}
            >
              <table className="table">
                <tbody>
                  {table.map(({ header, label, value }, i) => {
                    const isFirst = table[i - 1]?.header;
                    const isLast = !table[i + 1] || table[i + 1].header;

                    return (
                      <>
                        <tr
                          key={i}
                          className={
                            header
                              ? "header"
                              : `${isFirst ? "first" : ""} ${
                                  isLast ? "last" : ""
                                }`
                          }
                        >
                          {[value].includes("") ? (
                            ""
                          ) : header ? (
                            <td colSpan={2}>
                              <span>{header}</span>
                            </td>
                          ) : (
                            <>
                              <td className="bold">{label}</td>

                              <td>{value}</td>
                            </>
                          )}
                        </tr>
                      </>
                    );
                  })}

                  {/* <td className="bold">Vehicles</td>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {vehicles?.map((el: any) => {
                    return (
                      <td className={"textVehicle"} key={el.id}>
                        {el.vin}
                      </td>
                    );
                  })}
                </div> */}

                  {/* <tr className={"header"} style={{ marginTop: 2 }}>
                    <td colSpan={2}>
                      <span>Geofence Details</span>
                    </td>
                  </tr> */}
                </tbody>
              </table>

              {/* <MapWrapper
                loading={isLoading}
                data={fenceData}
                type={type}
                open={open}
              /> */}
            </Box>
          </Box>
        )}

        {tab === 1 && (
          <Box
            sx={{
              px: 3,
              // pt: 2.5,
            }}
          >
            <MapWrapper
              loading={isLoading}
              data={fenceData}
              // type={type}
              open={open}
            />
          </Box>
        )}

        {tab === 2 && (
          <Box mt={3} flexGrow={1} overflow="auto">
            <Table
              idKey={"_id"}
              selectable={false}
              rows={vehicles || []}
              columns={[
                {
                  key: "vin",
                  label: "vin",
                },
                {
                  key: "rentalStatus",
                  label: "Rental Status",
                  Render: ({ rentalStatus }: any) => {
                    return (
                      <Avatar
                        variant="status"
                        className={
                          rentalStatus === "INSTOCK"
                            ? "blue"
                            : rentalStatus === "BOOKED"
                            ? "yellow"
                            : rentalStatus === "AVAILABLE"
                            ? "green"
                            : "grey"
                        }
                      >
                        {rentalStatus
                          ? titleCase(rentalStatus.split("_").join(" "))
                          : "N/A"}
                      </Avatar>
                    );
                  },
                },

                {
                  key: "health",
                  label: "Health",
                  Render: ({ health }: any) => {
                    return (
                      <Avatar
                        variant="status"
                        className={
                          health === "active"
                            ? ""
                            : health === "moderate"
                            ? "yellow"
                            : health === "critical"
                            ? "red"
                            : "grey"
                        }
                      >
                        {health ? titleCase(health) : "Inactive"}
                      </Avatar>
                    );
                  },
                },
              ]}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

const MapWrapper = ({ loading, data }: any) => {
  return (
    <Box
      sx={{
        mt: 3,
        height: 500,
        width: 1,
        position: "relative",
        borderRadius: 5,
      }}
    >
      {loading ? (
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          animation="wave"
        />
      ) : (
        <Wrapper
          libraries={["visualization", "places", "drawing", "geometry"]}
          apiKey={GMAPS_API_KEY}
        >
          <Map data={data} />
        </Wrapper>
      )}
    </Box>
  );
};

const Map = ({ data }: any) => {
  const ref = useRef<HTMLElement | null>(null);

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [circle, setCircle] = useState<google.maps.Circle | null>(null);
  const [polygon, setPolygon] = useState<google.maps.Polygon | null>(null);

  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );

  console.log(data);

  useEffect(() => {
    if (ref.current) {
      setMap(
        new window.google.maps.Map(ref.current, {
          mapId: isDarkMode ? "e2d8edaa01f13e66" : "ad2188b39fd828f6",
          zoom: 12,
          center: { lat: 18.9716, lng: 97.5946 },
          disableDefaultUI: true,
        } as google.maps.MapOptions)
      );
    }
  }, [ref, isDarkMode]);

  useEffect(() => {
    if (!map) return;
    if (data) {
      if (data.data[0].type === "CIRCULAR") {
        console.log(data);
        polygon?.setMap(null);
        circle?.setMap(null);
        setCircle(
          new google.maps.Circle({
            strokeColor: "#61D169",
            strokeOpacity: 1,
            strokeWeight: 2,
            fillColor: "#61D169",
            fillOpacity: 0.35,
            radius: data.data[0].radius,
            center: {
              lat: data.data[0].location.coordinates[0]?.[0]?.[1],
              lng: data.data[0].location.coordinates[0]?.[0]?.[0],
            },
            editable: false,
            draggable: false,
            map,
          })
        );
      }

      if (data.data[0].type === "POLYGONAL") {
        polygon?.setMap(null);
        circle?.setMap(null);
        const polyCords = [...data?.data[0]?.location?.coordinates[0]];
        polyCords.pop();
        console.log(data?.data[0]?.location?.coordinates[0]);
        // eslint-disable-next-line
        let createPathArray: any = [];
        // eslint-disable-next-line
        polyCords.map((el: any) => {
          createPathArray.push({ lat: el[1], lng: el[0] });
        });

        setPolygon(
          new google.maps.Polygon({
            strokeColor: "#61D169",
            strokeOpacity: 1,
            strokeWeight: 2,
            fillColor: "#61D169",
            fillOpacity: 0.35,
            paths: createPathArray,
            editable: false,
            draggable: false,
            map,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, data]);

  useEffect(() => {
    if (circle) {
      if (!map) return;
      const circleBounds: any = circle?.getBounds();
      map.fitBounds(circleBounds);
    }
  }, [circle, map]);

  useEffect(() => {
    if (polygon) {
      if (!map) return;
      const polygonCoords = data?.data[0]?.location?.coordinates[0].map(
        (el: any) => {
          return new google.maps.LatLng(el[1], el[0]);
        },
        []
      );

      let bounds = new google.maps.LatLngBounds();
      polygonCoords?.forEach((latLng: any) => {
        bounds.extend(latLng);
      });

      map.setCenter(bounds.getCenter());
      map.fitBounds(bounds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polygon, map]);

  return (
    <>
      <Box
        ref={ref}
        sx={{
          position: "absolute",
          width: 1,
          height: 1,
          color: "black",
          borderRadius: 2,
        }}
      />
    </>
  );
};

export default InfoDrawer;
