import { AddBusinessOutlined, Edit, PersonAddAlt } from "@mui/icons-material";
import {
  alpha,
  Box,
  Button,
  IconButton,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import Search from "components/Search";
import Table from "components/Table";
import { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { GlobalState, authorizedFetch, getPermissions, titleCase } from "utils";
import { AUTH_URL } from "utils/constants";
import CompanyInviteDialog from "./components/CompanyInviteDialog";
import EditPermsDialog from "./components/EditPermsDialog";
import InviteDialog from "./components/InviteDialog";

const Admin = () => {
  const [tab, setTab] = useState(0);
  const [editDialog, setEditDialog] = useState({ open: false, user: {} });

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");

  const { activeSection, cmsPlan } = useSelector(
    (state: GlobalState) => state.global,
  );
  const { canWrite } = getPermissions(
    activeSection === "charger"
      ? "charger:admin"
      : activeSection === "rental"
        ? "rental:admin"
        : "retail:admin",
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [inviteDialog, setInviteDialog] = useState(false);
  const [companyInviteDialog, setCompanyInviteDialog] = useState(false);

  const employeesUrl = `${AUTH_URL}/company/users?first=${pageSize}&skip=${
    pageSize * (page - 1)
  }&search=${search}`;

  const {
    isLoading: employeesLoading,
    data: employeesData,
    remove,
    refetch,
  } = useQuery(["getEmployees", page, pageSize, search], () =>
    authorizedFetch(employeesUrl),
  );

  return (
    <Box
      sx={{
        px: { xs: 2, md: 4 },
        pt: { xs: 2, md: 4 },
        pb: { xs: 3, md: 6 },
      }}
    >
      <EditPermsDialog
        open={editDialog.open}
        handleClose={() => setEditDialog((prev) => ({ ...prev, open: false }))}
        user={editDialog.user}
        refresh={() => {
          remove();
          refetch();
        }}
      />
      <InviteDialog
        open={inviteDialog}
        handleClose={() => setInviteDialog(false)}
      />
      <CompanyInviteDialog
        open={companyInviteDialog}
        handleClose={() => setCompanyInviteDialog(false)}
      />
      {/* {true && ( */}
      {!!cmsPlan && ["PAYGP", "ASSP"].includes(cmsPlan) && (
        <Box
          sx={{
            py: 2,
            px: 3,
            mb: { xs: 2, md: 3 },
            borderRadius: 2,
            bgcolor: (theme) => alpha(theme.palette.warning.light, 0.15),
            border: (theme) =>
              `1px solid ${alpha(theme.palette.warning.light, 0.2)}`,
            fontSize: 14,
          }}
        >
          You are currently subscribed to the{" "}
          {cmsPlan === "PAYGP"
            ? "Pay-As-You-Go"
            : "Annual Software Subscription"}{" "}
          plan. For more details or to upgrade your plan, please{" "}
          <Link
            color="warning.main"
            href="https://bolt.earth/contact-us"
            target="_blank"
            rel="noreferrer"
          >
            contact our customer support team
          </Link>{" "}
          or your dedicated key account manager.
          {/* Your plan is set to expire on DD-MM-YYYY. */}
        </Box>
      )}
      <Paper
        sx={{
          width: 1,
          boxShadow: (theme) => theme.customShadows.card,
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            width: 1,
            p: 3,
            pb: 2.75,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box width="fit-content">
            <Tabs value={tab} onChange={(e, tab) => setTab(tab)}>
              <Tab
                label="Members"
                className="hasCount"
                sx={{
                  "&:after": {
                    content: `"${employeesData?.data?.count || "-"}"`,
                  },
                }}
              />
            </Tabs>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& > :not(:first-of-type)": {
                ml: 1.25,
              },
            }}
          >
            <Search
              handleSearch={(value) => {
                setPage(1);
                setSearch(value);
              }}
              persist
              enableClear
            />
            <Box>
              <Button
                sx={{
                  px: 2.5,
                  textTransform: "none",
                  fontWeight: 500,
                  borderColor: "primary.main",
                }}
                variant="outlined"
                className="rounded"
                // startIcon={
                //   <Add
                //     fontSize="inherit"
                //     sx={{ fontSize: "18px !important" }}
                //   />
                // }
                onClick={(e) =>
                  canWrite
                    ? setAnchorEl(e.currentTarget)
                    : setInviteDialog(true)
                }
              >
                Invite
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem
                  onClick={() => {
                    setInviteDialog(true);
                    setAnchorEl(null);
                  }}
                >
                  <ListItemIcon
                    children={<PersonAddAlt sx={{ color: "text.primary" }} />}
                  />
                  Member
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setCompanyInviteDialog(true);
                    setAnchorEl(null);
                  }}
                >
                  <ListItemIcon
                    children={
                      <AddBusinessOutlined sx={{ color: "text.primary" }} />
                    }
                  />
                  Company
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Box>
        <Table
          idKey="_id"
          serverSidePagination
          rowCount={employeesData?.data?.count || 0}
          activePage={page}
          activePageSize={pageSize}
          onPageChange={(value) => setPage(value)}
          onPageSizeChange={(value) => setPageSize(value)}
          loading={employeesLoading}
          rows={employeesData?.data?.users || []}
          columns={[
            {
              key: "name",
              label: "Name",
              Render: (row) => {
                let { firstName, lastName } = row;
                return firstName || lastName
                  ? `${firstName ? titleCase(firstName) : ""} ${
                      lastName ? titleCase(lastName) : ""
                    }`
                  : "-";
              },
            },
            {
              key: "email",
              label: "Email",
            },
            {
              key: "phone",
              label: "Phone",
              Render: (row) => {
                return (
                  <>
                    {row.phone ? (
                      <Box>
                        {row.phone.charAt(0) === `+`
                          ? row.phone.slice(0, 3) + " - " + row.phone.slice(3)
                          : "+91 - " + row.phone}
                      </Box>
                    ) : (
                      <Box>Not Found</Box>
                    )}
                  </>
                );
              },
            },
            {
              key: "permissions",
              label: "Permissions",
              Render: (row) => {
                let { permissions } = row;

                let permission = permissions?.includes("dashboard:*")
                  ? "Admin"
                  : permissions?.includes("dashboard:*:*:READ")
                    ? "Read"
                    : permissions?.includes("dashboard:*:*:WRITE")
                      ? "Write"
                      : "Scoped";
                return (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width={90}
                  >
                    {permission}
                    <Tooltip title="Edit">
                      <IconButton
                        sx={{ ml: 1 }}
                        children={<Edit fontSize="small" />}
                        size="small"
                        onClick={() => setEditDialog({ open: true, user: row })}
                      />
                    </Tooltip>
                  </Box>
                );
              },
            },
          ]}
        />
      </Paper>
    </Box>
  );
};

export default Admin;
