import {
  HighlightOff,
  InfoOutlined,
  Sync,
  SyncProblem,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import CircularLoader from "components/CircularLoader";
import { queryClient } from "index";
import moment from "moment";
import { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import {
  authorizedFetch,
  drawer,
  getDarkModePreference,
  getDuration,
  GlobalState,
  setLoader,
  snackbar,
  titleCase,
} from "utils";
import {
  BOLT_EARTH_COMPANY_ID,
  BOLT_SERVICE_URL,
  BOLT_URL,
  OCPP_URL,
  PAYMENTS_URL,
  WIFI_URL,
} from "utils/constants";
// import BookingsEarnings from "./BookingsEarnings";
import InfoTable from "components/InfoTable";
import ChargerLogs from "./ChargerLogs";

const InfoDrawer = ({ booking }: any) => {
  const [initiateRefundDialog, setInitiateRefundDialog] = useState(false);
  const [initiateSettlementDialog, setInitiateSettlementDialog] =
    useState(false);
  const [endBookingDialog, setEndBookingDialog] = useState(false);

  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state),
  );

  // const theme = useTheme();

  const [tab, setTab] = useState("booking");

  let { user, company } = useSelector((state: GlobalState) => state.global);
  const showRefundFlow = [
    "vishal.trivedi@bolt.earth",
    "aswathy.tp@bolt.earth",
    "walletmanager@bolt.earth",
    "abhishek.kh@bolt.earth",
    "thameeza.banu@bolt.earth",
    "athul.sivadas@bolt.earth",
    "akshata.nagaraj@bolt.earth",
    "aparna.ghosh@bolt.earth",
    "mayank.shrivastava@bolt.earth",
    "abhishek.kh@bolt.earth",
    "isha.gupta@bolt.earth",
    "pratik.var@bolt.earth",
  ].includes(user.email);
  const isBoltAccount = company.id === BOLT_EARTH_COMPANY_ID;
  const isCUGMonitorAccount = company?.type === "CUG_VENDOR";

  const bookingUrl = `${BOLT_URL}/company/booking/${booking?.bookingId}`;
  const { isLoading: bookingLoading, data: bookingData } = useQuery(
    ["getBooking", booking?.bookingId],
    () => authorizedFetch(bookingUrl),
    {
      onSettled: (data) => {
        if (![200, 201].includes(data.status)) {
          snackbar.error("Error fetching booking data");
          // drawer.close();
        }
      },
    },
  );

  const isOCPP = booking?.communicationProtocol === "OCPP";
  const isMQTT = booking?.communicationProtocol === "MQTT";
  const shouldShowLiveData =
    booking?.bookingStatus === "BOOKED" && (isOCPP || isMQTT);

  const url = isOCPP
    ? `${OCPP_URL}/charger/getChargerData`
    : `${WIFI_URL}/fetchEnergyConsumedV2`;

  const { isLoading: chargerLoading, data: chargerData } = useQuery(
    [
      "getChargerData",
      booking?.chargerId,
      booking?.connectorId,
      booking?.bleMac,
      booking?.bookingId,
    ],
    () =>
      authorizedFetch(url, {
        method: "POST",
        headers: {
          stage: "test",
          "Content-Type": "application/json",
        },
        body: isOCPP
          ? {
              chargerId: booking?.chargerId + "/" + booking?.connectorId,
              action: "currentMeterValues",
            }
          : {
              deviceId: booking?.bleMac?.replaceAll(":", ""),
              bookingId: booking?.bookingId,
            },
      }),
    {
      enabled: shouldShowLiveData,
      refetchInterval: shouldShowLiveData ? 10000 : false,
    },
  );

  const bookingSettlementUrl = `${PAYMENTS_URL}/v1/payments/settlement/singlesettlement/payout?bookingId=${booking?.bookingId}`;
  const { data: bookingSettlementData } = useQuery(
    ["getBookingsSettlement", booking?.bookingId],
    () => authorizedFetch(bookingSettlementUrl),
    {
      enabled: !isCUGMonitorAccount && !!booking?.bookingId,
    },
  );

  function initiateSettlement() {
    setLoader(true);
    const url = `${PAYMENTS_URL}/v1/payments/payouts/cashfree/settlements`;
    authorizedFetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        bookingId: booking.bookingId,
      },
    })
      .then((res) => {
        setLoader(false);
        if (res.status >= 400) {
          snackbar.error(res?.data?.message || "Error initiating settlement");
        } else {
          snackbar.success("Settlement initiated");
          queryClient.resetQueries("getBooking");
          queryClient.resetQueries("getBookings");
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
      });
  }

  function initiateRefund() {
    setLoader(true);
    const url = `${PAYMENTS_URL}/v1/payments/refunds/cashfree/initiatedirectrefund`;
    authorizedFetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        bookingId: booking.bookingId,
        amount: booking.amount,
        remark: "Customer requested Refund",
      },
    })
      .then((res) => {
        setLoader(false);
        if (res.status >= 400) {
          snackbar.error(res.message || "Error initiating refund");
        } else {
          snackbar.success("Refund initiated");
          queryClient.resetQueries("getBooking");
          queryClient.resetQueries("getBookings");
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
      });
  }

  const { value, unit } = chargerData?.data || {};

  const liveEnergyConsumed = shouldShowLiveData
    ? isOCPP
      ? // For OCPP
        (value ? value.toFixed(3) : 0) + " " + (unit || "kWh")
      : // For MQTT
        (chargerData?.energyConsumed
          ? (chargerData?.energyConsumed / 1000).toFixed(3)
          : 0) + " kWh"
    : null;

  const energyConsumed =
    (booking?.energyConsumed ? booking.energyConsumed.toFixed(3) : 0) + " kWh";

  const currencySymbol = bookingData?.data?.currencySymbol || "₹";

  const isOCPIBooking = booking?.bookingType?.toUpperCase() === "OCPI";
  const isSynced = isOCPIBooking
    ? booking?.ocpi?.cdrSynced
    : booking?.kibanaSynced;
  const lastSyncTime = isOCPIBooking
    ? booking?.ocpi?.lastUpdated
    : booking?.summarySyncTime;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            px: 3,
            py: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: isDarkMode ? "#000" : "#202020",
            fontWeight: 500,
            color: "#fff",
          }}
        >
          <Box>
            Booking{" "}
            <span style={{ fontSize: "0.9em" }}>#{booking.bookingId}</span>
          </Box>
          <Box display="flex" alignItems="center">
            {bookingData?.data?.bookingStatus === "BOOKED" && (
              <Button
                className="rounded"
                variant="contained"
                sx={{
                  textTransform: "none",
                  mr: 1,
                }}
                onClick={() => setEndBookingDialog(true)}
              >
                End Booking
              </Button>
            )}
            {/* <IconButton
                sx={{ mr: 1 }}
                children={<DeleteOutline />}
                color="inherit"
                size="small"
                onClick={() => setDeleteDialog(true)}
              /> */}
            <IconButton
              children={<HighlightOff />}
              color="inherit"
              size="small"
              onClick={() => drawer.close()}
            />
          </Box>
        </Box>
        <Box
          sx={{
            p: 2,
            pb: 0,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Tabs
            className="dense"
            variant="scrollable"
            // scrollButtons
            value={tab}
            onChange={(e: any, tab: any) => {
              setTab(tab);
            }}
            sx={{
              "&:after": {
                height: "1px",
              },
              "& .MuiTabs-indicator": {
                height: "2px",
              },
            }}
          >
            <Tab label="Booking" value="booking" />
            <Tab label="Invoice" value="invoice" />
            <Tab label="Estimation" value="estimation" />
            <Tab label="User Details" value="user-details" />
            {booking.bookingType !== "OCPI" && isBoltAccount && (
              <Tab label="Charger Logs" value="charger-logs" />
            )}
            {showRefundFlow && <Tab label="Refund" value="refund" />}
          </Tabs>
        </Box>
        <Box flexGrow={1} overflow="auto" px={2} pt={2.5} pb={4}>
          {tab === "booking" && (
            <>
              {bookingLoading ? (
                <CircularLoader />
              ) : (
                <InfoTable
                  data={[
                    {
                      heading: "Booking Details",
                      rows: [
                        {
                          label: "Booking Start",
                          value: bookingData?.data?.bookingStart
                            ? moment(bookingData?.data?.bookingStart).format(
                                "MMM DD YYYY, hh:mm a",
                              )
                            : "-",
                        },
                        {
                          label: "Booking End",
                          value: bookingData?.data?.bookingEnd
                            ? moment(bookingData?.data?.bookingEnd).format(
                                "MMM DD YYYY, hh:mm a",
                              )
                            : "-",
                        },
                        {
                          label: "Charging Start",
                          value: bookingData?.data?.chargingStart
                            ? moment(bookingData?.data?.chargingStart).format(
                                "MMM DD YYYY, hh:mm a",
                              )
                            : "-",
                        },
                        {
                          label: "Charging End",
                          value: bookingData?.data?.chargingEnd
                            ? moment(bookingData?.data?.chargingEnd).format(
                                "MMM DD YYYY, hh:mm a",
                              )
                            : "-",
                        },
                        {
                          label: "Booking Duration",
                          value:
                            typeof bookingData?.data?.bookingDuration ===
                            "number"
                              ? getDuration(
                                  bookingData?.data?.bookingDuration * 60,
                                )
                              : "-",
                        },
                        {
                          label: "Charging Duration",
                          value:
                            typeof bookingData?.data?.chargingDuration ===
                            "number"
                              ? getDuration(
                                  bookingData?.data?.chargingDuration * 60,
                                )
                              : "-",
                        },
                        {
                          label: (
                            <Box display="flex" alignItems="center">
                              Sync Status
                              <Tooltip
                                title="(will be CDR sync in CPO)"
                                placement="right"
                              >
                                <InfoOutlined
                                  sx={{
                                    ml: 0.5,
                                    fontSize: 14,
                                    color: (theme) => theme.customColors.action,
                                  }}
                                />
                              </Tooltip>
                            </Box>
                          ),
                          value:
                            isSynced === true ? (
                              <Box display="flex" alignItems="center">
                                <Sync
                                  fontSize="small"
                                  color="success"
                                  sx={{ mr: 0.5 }}
                                />
                                Synced
                              </Box>
                            ) : (
                              <Box display="flex" alignItems="center">
                                <SyncProblem
                                  fontSize="small"
                                  color="warning"
                                  sx={{ mr: 0.5 }}
                                />
                                Not synced
                              </Box>
                            ),
                        },
                        {
                          label: (
                            <Box display="flex" alignItems="center">
                              Sync Time
                              <Tooltip
                                title="(will be CDR sync time in CPO)"
                                placement="right"
                              >
                                <InfoOutlined
                                  sx={{
                                    ml: 0.5,
                                    fontSize: 14,
                                    color: (theme) => theme.customColors.action,
                                  }}
                                />
                              </Tooltip>
                            </Box>
                          ),
                          value: lastSyncTime
                            ? moment(lastSyncTime).format(
                                "MMM DD YYYY, hh:mm a",
                              )
                            : "-",
                        },
                        {
                          label: "Freeze State",
                          value: bookingData?.data?.syncMetadata?.isFrozen
                            ? "True"
                            : "False",
                        },
                        {
                          label: "Freeze Time",
                          value: bookingData?.data?.syncMetadata?.frozenAt
                            ? moment(
                                bookingData.data.syncMetadata.frozenAt,
                              ).format("MMM DD YYYY, hh:mm a")
                            : "-",
                        },
                      ],
                    },
                  ]}
                />
              )}
            </>
          )}
          {tab === "invoice" && (
            <>
              {bookingLoading ? (
                <CircularLoader />
              ) : (
                <InfoTable
                  data={[
                    {
                      heading: "Invoice Details",
                      rows: [
                        {
                          label: "Charging Duration",
                          value:
                            typeof bookingData?.data?.chargingDuration ===
                            "number"
                              ? getDuration(
                                  bookingData?.data?.chargingDuration * 60,
                                )
                              : "-",
                        },
                        {
                          label: "Energy Consumed",
                          value: shouldShowLiveData
                            ? chargerLoading
                              ? "Loading..."
                              : liveEnergyConsumed
                            : energyConsumed,
                        },
                        {
                          label: "Charging Price",
                          value:
                            typeof bookingData?.data?.costPerkWh === "number"
                              ? `${currencySymbol}${bookingData?.data?.costPerkWh}`
                              : "-",
                        },
                        {
                          label: "Platform Fees",
                          value:
                            typeof bookingData?.data?.platformFeePerkWh ===
                            "number"
                              ? `${currencySymbol}${bookingData?.data?.platformFeePerkWh}`
                              : "-",
                        },
                        {
                          label: "Flat Discount",
                          value: `${currencySymbol}${
                            (bookingData?.data?.couponDiscount || 0) +
                            (bookingData?.data?.platformFeeDiscount || 0) +
                            (bookingData?.data?.subscriptionDiscount || 0)
                          }`,
                        },
                        {
                          label: <b>Total Bill Amount</b>,
                          value: (
                            <b>{`${currencySymbol}${bookingData?.data?.amount || 0}`}</b>
                          ),
                        },
                      ],
                    },
                    {
                      heading: "Additional Details",
                      rows: [
                        {
                          label: "Payment Status",
                          value:
                            booking?.paymentStatus
                              ?.split("_")
                              ?.map(titleCase)
                              ?.join(" ") || "-",
                        },
                        {
                          label: "Settlement Status",
                          value:
                            bookingSettlementData?.data?.status === "PENDING"
                              ? "Pending"
                              : bookingSettlementData?.data?.status ===
                                  "REVERSED"
                                ? "Pending on Payment Gateway Side"
                                : bookingSettlementData?.data?.status ===
                                    "PENDING_AT_BANK"
                                  ? "Pending on Bank Side"
                                  : bookingSettlementData?.data?.status ===
                                      "SUCCESS"
                                    ? "Settled"
                                    : "-",
                        },
                        {
                          label: "Settlement UTR",
                          value: bookingSettlementData?.data?.utr || "-",
                        },
                      ],
                    },
                  ]}
                />
              )}
            </>
          )}
          {tab === "estimation" && (
            <>
              {bookingLoading ? (
                <CircularLoader />
              ) : (
                <InfoTable
                  data={[
                    {
                      heading: "Estimation Details",
                      rows: [
                        {
                          label: "Booking Duration",
                          value:
                            typeof bookingData?.data?.bookingDuration ===
                            "number"
                              ? getDuration(
                                  (bookingData?.data?.bookingDuration || 0) *
                                    60,
                                )
                              : "-",
                        },
                        {
                          label: "Estimated Consumption",
                          value:
                            typeof booking?.estimatedEnergyConsumption ===
                            "number"
                              ? (booking?.estimatedEnergyConsumption
                                  ? booking.estimatedEnergyConsumption.toFixed(
                                      3,
                                    )
                                  : 0) + " kWh"
                              : "-",
                        },

                        {
                          label: "Estimated Charging Price",
                          value:
                            typeof bookingData?.data?.estimatedChargingFee ===
                            "number"
                              ? `${currencySymbol}${bookingData?.data?.estimatedChargingFee}`
                              : "-",
                        },
                        {
                          label: "Estimated Platform Fees",
                          value:
                            typeof bookingData?.data?.estimatedPlatformFee ===
                            "number"
                              ? `${currencySymbol}${bookingData?.data?.estimatedPlatformFee}`
                              : "-",
                        },
                        {
                          label: "Flat Discount",
                          value: `${currencySymbol}${
                            (bookingData?.data?.couponDiscount || 0) +
                            (bookingData?.data?.platformFeeDiscount || 0) +
                            (bookingData?.data?.subscriptionDiscount || 0)
                          }`,
                        },
                        {
                          label: <b>Estimated Bill Amount</b>,
                          value: (
                            <b>{`${currencySymbol}${bookingData?.data?.estimatedAmount || 0}`}</b>
                          ),
                        },
                      ],
                    },
                  ]}
                />
              )}
            </>
          )}
          {tab === "user-details" && (
            <>
              {bookingLoading ? (
                <CircularLoader />
              ) : (
                <InfoTable
                  data={[
                    {
                      heading: "User Details",
                      rows: [
                        {
                          label: "Name",
                          value: bookingData?.data?.userName || "-",
                        },
                        {
                          label: "Phone",
                          value: bookingData?.data?.userPhone
                            ? bookingData.data.userPhone.slice(0, 3) +
                              " " +
                              bookingData.data.userPhone.slice(3)
                            : "-",
                        },
                        {
                          label: "Email",
                          value: bookingData?.data?.userEmail || "-",
                        },
                      ],
                    },
                    {
                      heading: "Vehicle Details",
                      rows: [
                        {
                          label: "Vehicle Company",
                          value: booking?.vehicleCompany || "-",
                        },
                        {
                          label: "Vehicle Model",
                          value: booking?.vehicleModel || "-",
                        },
                        {
                          label: "Vehicle Type",
                          value:
                            booking?.vehicleType
                              ?.split("_")
                              ?.map(titleCase)
                              ?.join(" ") || "-",
                        },
                      ],
                    },
                  ]}
                />
              )}
            </>
          )}
          {tab === "charger-logs" && (
            <ChargerLogs
              bookingId={booking?.bookingId}
              isOCPP={booking?.communicationProtocol === "OCPP"}
            />
          )}
          {tab === "refund" && (
            <>
              {bookingLoading ? (
                <CircularLoader />
              ) : (
                <>
                  {bookingData?.data?.amount === 0 ||
                  bookingData?.data?.bookingStatus === "BOOKED" ||
                  bookingData?.data?.bookingStatus === "PAYMENT_PENDING" ? (
                    <Alert
                      severity="info"
                      sx={{ maxWidth: 200, mt: 1, mx: "auto" }}
                    >
                      No refund request
                    </Alert>
                  ) : (
                    <InfoTable
                      data={[
                        {
                          heading: "Refund Details",
                          rows: [
                            {
                              label: "Refund Amount",
                              value: bookingData?.data?.refundAmount
                                ? "₹" + bookingData?.data?.refundAmount
                                : "-",
                            },
                            {
                              label: "Status",
                              value: bookingData?.data?.refundStatus || "-",
                            },

                            {
                              label: "Initiated On",
                              value: !bookingData?.data?.refundDate
                                ? "-"
                                : moment(bookingData?.data?.refundDate).format(
                                    "MMM DD YYYY, hh:mm a",
                                  ),
                            },
                          ],
                        },
                      ]}
                    />
                  )}
                  {bookingData?.data?.amount === 0 ||
                  bookingData?.data?.refundStatus === "COMPLETED" ||
                  bookingData?.data?.refundStatus === "INITIATED" ||
                  bookingData?.data?.bookingStatus === "BOOKED" ||
                  bookingData?.data?.bookingStatus ===
                    "PAYMENT_PENDING" ? null : (
                    <Box my={2} ml={2}>
                      <Button
                        variant="contained"
                        onClick={() => setInitiateRefundDialog(true)}
                      >
                        Initiate
                      </Button>
                    </Box>
                  )}
                </>
              )}
            </>
          )}
        </Box>
      </Box>
      <EndBookingDialog
        open={endBookingDialog}
        handleClose={() => {
          setEndBookingDialog(false);
        }}
        booking={bookingData?.data || {}}
      />
      <RefundDialog
        open={initiateRefundDialog}
        handleClose={() => {
          setInitiateRefundDialog(false);
          // drawer.close();
        }}
        initiate={() => {
          initiateRefund();
          setInitiateRefundDialog(false);
          // drawer.close();
        }}
      />
      <SettlementDialog
        open={initiateSettlementDialog}
        handleClose={() => {
          setInitiateSettlementDialog(false);
          // drawer.close();
        }}
        initiate={() => {
          initiateSettlement();
          setInitiateSettlementDialog(false);
          // drawer.close();
        }}
      />
    </>
  );
};

const RefundDialog = ({ open, handleClose, initiate }: any) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Initiate Refund</DialogTitle>
      <DialogContent sx={{ marginTop: "15px" }}>
        Are you sure you want to initiate refund?{" "}
      </DialogContent>
      <DialogActions sx={{ margin: "15px 15px 15px 0" }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={initiate}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const SettlementDialog = ({ open, handleClose, initiate }: any) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Initiate Settlement</DialogTitle>
      <DialogContent sx={{ marginTop: "15px" }}>
        Are you sure you want to initiate settlement?{" "}
      </DialogContent>
      <DialogActions sx={{ margin: "15px 15px 15px 0" }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={initiate}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EndBookingDialog = ({ open, handleClose, booking }: any) => {
  const handleConfirm = () => {
    setLoader(true);
    const endBookingUrl =
      booking.bookingType === "OCPI"
        ? `${BOLT_SERVICE_URL}/charger/booking/${booking._id}/end-async`
        : `${BOLT_SERVICE_URL}/charger/${booking._id}/end`;

    authorizedFetch(endBookingUrl, {
      method: "POST",
    })
      .then((res) => {
        setLoader(false);
        if (res.status === 200 || res.result === "ACCEPTED") {
          snackbar.success("Booking ended");
          queryClient.resetQueries("getBooking");
          queryClient.resetQueries("getBookings");
          handleClose();
        } else {
          snackbar.error(res?.data?.message || "Error ending booking");
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
        snackbar.error("Error ending booking");
      });
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>End Booking</DialogTitle>
      <DialogContent>Are you sure you want to end this booking? </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InfoDrawer;
