import { useEffect, useRef, useState } from "react";
import { Box, Skeleton } from "@mui/material";
import { Wrapper } from "@googlemaps/react-wrapper";
// import { GMAPS_API_KEY } from "utils/constants";
import { useSelector } from "react-redux";
import { getDarkModePreference, GlobalState } from "utils";
import { GMAPS_API_KEY } from "utils/constants";

const TripsMap = (props: any) => {
  return (
    <Box
      sx={{
        position: "relative",
        height: 1,
        minHeight: 200,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "14px",
        overflow: "hidden",
      }}
    >
      {props.loading ? (
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          animation="wave"
        />
      ) : (
        <Wrapper
          libraries={["visualization", "places", "drawing", "geometry"]}
          apiKey={GMAPS_API_KEY}
        >
          <GoogleMap {...props} />
        </Wrapper>
      )}
    </Box>
  );
};

const GoogleMap = ({ data }: { data: any[] }) => {
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );
  const ref = useRef<HTMLElement | null>(null);

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [heatmap, setHeatmap] =
    useState<google.maps.visualization.HeatmapLayer | null>(null);

  useEffect(() => {
    setMap(
      new window.google.maps.Map(
        ref.current as HTMLElement,
        {
          mapId: isDarkMode ? "e2d8edaa01f13e66" : "ad2188b39fd828f6",
          center: { lat: 12.9716, lng: 77.5946 },
          zoom: 12,
          // disableDefaultUI: true,
          streetViewControl: false,
          zoomControl: true,
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_BOTTOM,
          },
          fullscreenControl: true,
          fullscreenControlOptions: {
            position: google.maps.ControlPosition.RIGHT_BOTTOM,
          },
        } as google.maps.MapOptions
      )
    );
    // eslint-disable-next-line
  }, [isDarkMode]);

  useEffect(() => {
    if (!map) return;
    let heatmapData = data?.reduce((acc, cur, i) => {
      return [
        ...acc,
        ...cur?.locations?.map(
          (coord: any) =>
            new google.maps.LatLng(coord?.latitude, coord?.longitude)
        ),
      ];
    }, []);
    console.log(heatmapData);
    let bounds = new google.maps.LatLngBounds();
    heatmapData?.forEach((latLng: any) => {
      bounds.extend(latLng);
    });
    console.log(bounds);

    if (heatmap) heatmap.setData(heatmapData);
    else
      setHeatmap(
        new google.maps.visualization.HeatmapLayer({ data: heatmapData, map })
      );

    map.setCenter(bounds.getCenter());
    map.fitBounds(bounds);
    // eslint-disable-next-line
  }, [map, data]);

  return (
    <Box
      ref={ref}
      sx={{ position: "absolute", width: 1, height: 1, color: "black" }}
    />
  );
};

export default TripsMap;
