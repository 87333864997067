import { HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { queryClient } from "index";
import { authorizedFetch, setLoader, snackbar } from "utils";
import { BOLT_SERVICE_URL } from "utils/constants";

const CaptiveModeDialog = ({ open, handleClose, data }: any) => {
  const isCaptive = !!data?.charger?.attributes?.captive;

  function handleSave() {
    setLoader(true);
    authorizedFetch(
      `${BOLT_SERVICE_URL}/v2/charger/${data.chargerId}/toggle-captive-status`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          captive: !isCaptive,
        },
      },
    )
      .then((res) => {
        setLoader(false);
        if (res.meta.success) {
          snackbar.success(
            `${isCaptive ? "Disabled" : "Enabled"} captive mode for ${data.chargerId}`,
          );
          queryClient.resetQueries("getCharger");
          handleClose();
        } else {
          snackbar.error(`Error toggling captive status`);
        }
      })
      .catch((err) => {
        console.error(err);
        snackbar.error(`Error toggling captive status`);
      });
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          Toggle Captive Mode
          <Divider flexItem orientation="vertical" sx={{ mx: 1.5 }} />
          <Typography color="text.secondary">{data.chargerId}</Typography>
        </Box>
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 0.5, pb: 1.5 }}>
          <b>{isCaptive ? "Disable" : "Enable"}</b> captive mode for this
          charger?
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSave}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CaptiveModeDialog;
