import { useEffect, useRef, useState } from "react";
import { Box, Skeleton } from "@mui/material";
import { Wrapper } from "@googlemaps/react-wrapper";
// import { GMAPS_API_KEY } from "utils/constants";
import { useSelector } from "react-redux";
import { getDarkModePreference, GlobalState, validateLatLng } from "utils";
import { GMAPS_API_KEY } from "utils/constants";

const TripsMap = (props: any) => {
  return (
    <Box
      sx={{
        position: "relative",
        height: 1,
        minHeight: 300,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 1.5,
        overflow: "hidden",
      }}
    >
      {props.loading ? (
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          animation="wave"
        />
      ) : (
        <Wrapper
          libraries={["visualization", "places", "drawing", "geometry"]}
          apiKey={GMAPS_API_KEY}
        >
          <GoogleMap {...props} />
        </Wrapper>
      )}
    </Box>
  );
};

const GoogleMap = ({ data }: { data: any[] }) => {
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );
  const ref = useRef<HTMLElement | null>(null);

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [heatmap, setHeatmap] =
    useState<google.maps.visualization.HeatmapLayer | null>(null);

  useEffect(() => {
    setMap(
      new window.google.maps.Map(
        ref.current as HTMLElement,
        {
          mapId: isDarkMode ? "e2d8edaa01f13e66" : "ad2188b39fd828f6",
          center: { lat: 12.9716, lng: 77.5946 },
          zoom: 12,
          disableDefaultUI: true,
          // zoomControl: false,
          // streetViewControl: false,
          // fullscreenControl: false,
          // zoomControlOptions: {
          //   position: google.maps.ControlPosition.LEFT_BOTTOM,
          // },
        } as google.maps.MapOptions
      )
    );
    // eslint-disable-next-line
  }, [isDarkMode]);

  useEffect(() => {
    if (!map) return;

    let heatmapData = data.reduce((acc, cur, i) => {
      return [
        ...acc,
        ...cur?.locations
          ?.filter((el: any) => validateLatLng(el?.latitude, el?.longitude))
          .map((el: any) => new google.maps.LatLng(el.latitude, el.longitude)),
      ];
    }, []);

    let bounds = new google.maps.LatLngBounds();
    heatmapData.forEach((latLng: any) => {
      bounds.extend(latLng);
    });

    if (heatmap) heatmap.setData(heatmapData);
    else
      setHeatmap(
        new google.maps.visualization.HeatmapLayer({ data: heatmapData, map })
      );

    if (heatmapData.length) {
      map.setCenter(bounds.getCenter());
      map.fitBounds(bounds);
    }
    // eslint-disable-next-line
  }, [map, data]);

  return (
    <Box
      ref={ref}
      sx={{ position: "absolute", width: 1, height: 1, color: "black" }}
    />
  );
};

export default TripsMap;
