import { combineReducers } from "redux";
import { loadState } from "./utils/storageManager";
import assignIn from "lodash/assignIn";

const persistedState = loadState();

const initialState = assignIn(
  {
    firebase: {},
    token: null,
    user: {},
    userDetails: {},
    company: {},
    prefersDarkMode: false, // Can be true, false or null; use device-theme if null

    activeSection: null, // Can be 'retail', 'rental', or 'charger' (acc. to company permissions)
    masterView: false,
    loader: false,
    snackbar: {
      open: false,
      snackPack: [],
    },
    drawer: {
      open: false,
      content: null,
    },
    appBar: {
      open: false,
      content: null,
    },
    reports: [],
    vehicleReports: [],

    lastReadNotification: null,

    cmsPlan: null,
  },
  persistedState?.global || {},
);

function globalReducer(state = initialState, action) {
  let { type, payload } = action;
  switch (type) {
    case "SET_GLOBAL_STATE":
      return assignIn({}, state, payload);
    case "SWITCH_TO":
      return assignIn({}, state, { activeSection: payload });
    case "SAVE_REPORT":
      return assignIn({}, state, {
        reports: [...state.reports, payload],
      });
    case "SAVE_VEHICLE_REPORT":
      return assignIn({}, state, {
        vehicleReports: [...state.vehicleReports, payload],
      });
    case "LOGIN":
      return assignIn({}, state, { userDetails: payload });
    case "LOGIN_SUCCEEDED":
      return assignIn({}, state, { ...payload }); // Sets firebase, token, user, company, activeSection
    case "LOGIN_FAILED":
      return assignIn({}, state, { loginResponse: payload });
    case "SIGNUP":
      return assignIn({}, state, { ...payload });
    case "SIGNUP_SUCCEEDED":
      return assignIn({}, state, { ...payload });
    case "SIGNUP_FAILED":
      return assignIn({}, state, { ...payload });
    case "LOGOUT":
      return assignIn({}, state, {
        token: null,
        userDetails: {},
        masterView: false,
        cmsPlan: null,
      });
    case "TOGGLE_DARK_MODE":
      return assignIn({}, state, { prefersDarkMode: payload });
    case "TOGGLE_LOADER": {
      return assignIn({}, state, { loader: payload });
    }
    case "TOGGLE_MASTER_VIEW": {
      return assignIn({}, state, { masterView: payload });
    }
    case "TOGGLE_SNACKBAR": {
      return assignIn({}, state, { snackbar: payload });
    }
    case "TOGGLE_DRAWER": {
      return assignIn({}, state, { drawer: payload });
    }
    case "SET_CMS_PLAN": {
      return assignIn({}, state, { cmsPlan: payload });
    }
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  global: globalReducer,
});

export default rootReducer;
