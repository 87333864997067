import {
  AccountCircleOutlined,
  AdminPanelSettingsOutlined,
  Apps,
  CarRental,
  Construction,
  EventRepeatOutlined,
  EvStation,
  Menu,
  PrecisionManufacturing,
  Speed,
} from "@mui/icons-material";
import {
  alpha,
  Box,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Switch,
  Tooltip,
} from "@mui/material";
import { setCmsPlan, switchTo } from "actions";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  authorizedFetch,
  getDarkModePreference,
  getEnabledSections,
  // getSectionPermissions,
  GlobalState,
  setMasterView,
} from "utils";
import { BOLT_URL, HOST_SERVICE_URL } from "utils/constants";
import AccountPopover from "./AccountPopover";
import NotificationsPopover from "./NotificationsPopover";
import SearchBox from "./SearchBox";
// import storageManager from "utils/storageManager";

interface Props {
  v2: boolean;
  handleDrawerToggle: () => void;
  pageTitle: string;
}

const AppBar: React.FC<Props> = ({ v2, handleDrawerToggle, pageTitle }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const state = useSelector((state: GlobalState) => state);
  const isDarkMode = getDarkModePreference(state);
  const enabledSections = getEnabledSections(state);
  // const getSections = getSectionPermissions(state);
  const { user, company, masterView, activeSection, appBar, cmsPlan } =
    state.global;
  // const isCUGMonitorAccount = company?.type === "CUG_VENDOR";

  const showMasterViewSwitch =
    ["REVOS", "Bolt Earth"].includes(company?.name) &&
    ((activeSection === "charger" &&
      [
        "/overview",
        "/chargers",
        "/vendors",
        "/users",
        "/bookings",
        "/reports",
      ].includes(location.pathname)) ||
      (activeSection === "rental" &&
        ["/overview", "/leases"].includes(location.pathname)));

  const [sectionAnchor, setSectionAnchor] = useState<HTMLButtonElement | null>(
    null,
  );
  const [accountAnchor, setAccountAnchor] = useState<HTMLButtonElement | null>(
    null,
  );

  const { data: hostsListData } = useQuery(
    ["GET hosts list", user?.phone],
    () =>
      authorizedFetch(
        `${BOLT_URL}/company/hosts?first=1&phone=${user?.phone || ""}`,
      ),
    { enabled: !!user?.phone && !cmsPlan },
  );

  const hostId = hostsListData?.data?.users?.[0]?.user?._id;

  const { data: hostData } = useQuery(
    ["GET host", hostId],
    () => authorizedFetch(`${HOST_SERVICE_URL}/host/v1/${hostId}`),
    { enabled: !!hostId },
  );

  const fetchedCmsPlan = hostData?.data?.plan?.key;

  useEffect(() => {
    if (!fetchedCmsPlan) return;

    dispatch(setCmsPlan(fetchedCmsPlan));

    // eslint-disable-next-line
  }, [fetchedCmsPlan]);

  return (
    <Box sx={{ display: "grid", gridTemplateRows: `min-content auto` }}>
      <Box
        sx={{
          zIndex: 9,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: { xs: 1.5, md: 3 },
          py: { xs: 1, md: 1.75 },
          bgcolor: {
            xs: isDarkMode ? "#080808" : "#232728",
            md: "background.paper",
          },
          color: { xs: "white", md: "inherit" },
          boxShadow: "0 1px 4px rgba(21, 34, 50, 0.08)",
        }}
      >
        <Box display="flex" alignItems="center">
          <IconButton
            sx={{ mr: 1.5, display: { md: "none" } }}
            onClick={handleDrawerToggle}
            color="inherit"
          >
            <Menu />
          </IconButton>
          {!v2 && (
            <Box ml={1} display={{ xs: "none", md: "block" }}>
              <SearchBox />
            </Box>
          )}
          {v2 && (
            <Box
              sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
            >
              <Box
                sx={{
                  display: { xs: "none", lg: "unset" },
                  fontSize: 18,
                  color: (theme) => theme.customColors.text.grey,
                  fontWeight: 600,
                  lineHeight: "1em",
                }}
              >
                {activeSection === "charger"
                  ? "Charger Management"
                  : activeSection === "rental"
                    ? "Fleet Management"
                    : activeSection === "retail"
                      ? "Vehicle Management"
                      : "Housekeeping"}
              </Box>
              <Box
                sx={{
                  display: { xs: "none", md: "unset", lg: "none" },
                  fontSize: 18,
                  color: (theme) => theme.customColors.text.grey,
                  fontWeight: 600,
                  lineHeight: "1em",
                }}
              >
                {activeSection === "charger"
                  ? "CMS"
                  : activeSection === "rental"
                    ? "FMS"
                    : activeSection === "retail"
                      ? "VMS"
                      : "HKP"}
              </Box>
              <Box
                sx={{
                  mx: 1,
                  color: "text.disabled",
                  opacity: 0.5,
                  fontSize: 24,
                  lineHeight: 0,
                  fontWeight: 300,
                  userSelect: "none",
                  pointerEvents: "none",
                }}
              >
                /
              </Box>
              <Box
                sx={{
                  fontSize: 18,
                  color: (theme) => theme.customColors.text.plain,
                  fontWeight: 600,
                  lineHeight: "1em",
                }}
              >
                {pageTitle}
              </Box>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& > *:not(:first-of-type)": {
              ml: { xs: 1, md: 1.5 },
            },
          }}
        >
          {v2 && (
            <Box display={{ xs: "none", md: "block" }}>
              <SearchBox />
            </Box>
          )}

          {/* {true ? ( */}
          {!!cmsPlan && ["PAYGP", "ASSP"].includes(cmsPlan) ? (
            <Tooltip
              title={
                // true
                cmsPlan === "PAYGP"
                  ? "You're on the Pay-As-You-Go Plan"
                  : "You're on the Annual Software Subscription Plan"
              }
            >
              <Box
                sx={{
                  px: 1.5,
                  borderRadius: 10,
                  height: 36,
                  // border: (theme) =>
                  //   `1px solid ${alpha(theme.palette.warning.light, 0.2)}`,
                  bgcolor: (theme) => alpha(theme.palette.warning.light, 0.1),
                  color: "warning.main",
                  display: "flex",
                  alignItems: "center",
                  fontSize: 13,
                  lineHeight: "1em",
                  cursor:
                    location.pathname === "/admin" ? "default" : "pointer",
                }}
                onClick={() => {
                  if (location.pathname !== "/admin") {
                    history.push("/admin");
                  }
                }}
              >
                {/* {true ? ( */}
                {cmsPlan === "PAYGP" ? (
                  <>
                    <Speed
                      color="inherit"
                      fontSize="small"
                      sx={{
                        mr: 0.75,
                        color: (theme) => alpha(theme.palette.warning.main, 1),
                      }}
                    />
                    Pay-As-You-Go
                  </>
                ) : (
                  <>
                    <EventRepeatOutlined
                      color="inherit"
                      fontSize="small"
                      sx={{
                        mr: 0.75,
                        color: (theme) =>
                          alpha(theme.palette.warning.main, 0.8),
                      }}
                    />
                    Annual Subscription
                  </>
                )}
              </Box>
            </Tooltip>
          ) : null}
          {showMasterViewSwitch && (
            <Tooltip title="Master View: Switch this on to see all data for the Bolt.Earth platform irrespective of the company">
              <Box
                sx={{
                  px: 1,
                  borderRadius: 10,
                  height: 40,
                  // border: (theme) => `1px solid ${theme.palette.divider}`,
                  bgcolor: (theme) =>
                    isDarkMode ? theme.palette.background.default : "#f7f7f7",
                  color: "text.primary",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <label
                  htmlFor="master-view"
                  style={{
                    height: 24,
                    cursor: "pointer",
                    marginRight: 4,
                  }}
                >
                  <AdminPanelSettingsOutlined color="inherit" />
                </label>
                <Switch
                  id="master-view"
                  size="small"
                  checked={masterView}
                  onChange={() => setMasterView(!masterView)}
                />
              </Box>
            </Tooltip>
          )}
          <Box>
            <Tooltip title="Switch to...">
              <IconButton
                color="inherit"
                onClick={(e) => setSectionAnchor(e.currentTarget)}
              >
                <Apps />
              </IconButton>
            </Tooltip>
            <Popover
              open={Boolean(sectionAnchor)}
              anchorEl={sectionAnchor}
              onClose={() => setSectionAnchor(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              sx={{
                "& .MuiPaper-root": {
                  boxShadow: (theme) => theme.shadows[3],
                  borderRadius: 2,
                },
                "& .MuiList-root": {
                  p: 0.5,
                },
                "& .MuiListItemButton-root": {
                  borderRadius: 1.5,
                  "&.active": {
                    bgcolor: (theme) => theme.palette.action.selected,
                  },
                  height: 60,
                },
                "& .MuiListItemIcon-root": {
                  minWidth: 38,
                  color: "text.secondary",
                },
              }}
            >
              <List>
                {enabledSections.includes("retail") && (
                  <ListItemButton
                    className={activeSection === "retail" ? "active" : ""}
                    onClick={() => {
                      setSectionAnchor(null);
                      dispatch(switchTo("retail"));
                    }}
                  >
                    <ListItemIcon>
                      <PrecisionManufacturing />
                    </ListItemIcon>
                    <ListItemText
                      primary="Vehicle Management"
                      // secondary="Vehicle Management"
                    />
                  </ListItemButton>
                )}
                {enabledSections.includes("rental") && (
                  <ListItemButton
                    className={activeSection === "rental" ? "active" : ""}
                    onClick={() => {
                      setSectionAnchor(null);
                      dispatch(switchTo("rental"));
                    }}
                  >
                    <ListItemIcon>
                      <CarRental />
                    </ListItemIcon>
                    <ListItemText
                      primary="Fleet Management"
                      // secondary="Vehicle Lease Management"
                    />
                  </ListItemButton>
                )}
                {enabledSections.includes("charger") && (
                  <ListItemButton
                    className={activeSection === "charger" ? "active" : ""}
                    onClick={() => {
                      setSectionAnchor(null);
                      dispatch(switchTo("charger"));
                    }}
                  >
                    <ListItemIcon>
                      <EvStation />
                    </ListItemIcon>
                    <ListItemText
                      primary="Charger Management"
                      // secondary="Charger Management"
                    />
                  </ListItemButton>
                )}
                {
                  // (user?._id === "5cfa43c6a7b11b00073f9656" ||
                  //   user?._id === "624c0baac6cf8c0007883ae8" ||
                  //   company?.name === "REVOS") &&

                  enabledSections.includes("housekeeping") && (
                    <ListItemButton
                      className={
                        activeSection === "housekeeping" ? "active" : ""
                      }
                      onClick={() => {
                        setSectionAnchor(null);
                        dispatch(switchTo("housekeeping"));
                      }}
                    >
                      <ListItemIcon>
                        <Construction />
                      </ListItemIcon>
                      <ListItemText
                        primary="Housekeeping"
                        // secondary="In-House Management"
                      />
                    </ListItemButton>
                  )
                }
              </List>
            </Popover>
          </Box>
          {/* {!isCUGMonitorAccount && (
            <Box component={NavLink} to={"/developer"} color="inherit">
              <Tooltip title="Developer Section">
                <IconButton color="inherit">
                  <Code />
                </IconButton>
              </Tooltip>
            </Box>
          )} */}
          <NotificationsPopover />
          <Box>
            <Tooltip title="Account">
              <IconButton
                onClick={(e) => setAccountAnchor(e.currentTarget)}
                color="inherit"
              >
                <AccountCircleOutlined />
              </IconButton>
            </Tooltip>
            <AccountPopover
              anchor={accountAnchor}
              setAnchor={setAccountAnchor}
              isDarkMode={isDarkMode}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          height: appBar.open ? { xs: 56, md: 94 } : 0,
          zIndex: 8,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          bgcolor: "background.paper",
          backdropFilter: "saturate(180%) blur(20px)",
          boxShadow: "0px 4px 8px 0px #00000012",
          ...(!appBar.open
            ? {
                overflow: "hidden",
              }
            : {}),
        }}
      >
        {appBar.content}
      </Box>
    </Box>
  );
};

export default AppBar;
